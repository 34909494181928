import {
  Suspense,
  useContext,
  useReducer,
  createContext,
  useEffect,
  useState,
} from "react"
import { Link } from "react-router-dom"
import { node, string } from "prop-types"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import {
  AlertMessage,
  Loading,
  BreadcrumbNavigationProvider,
  BreadcrumbNavigationPortal,
} from "source/shared/components"
import { ProtectAgainstChangingSessions } from "source/shared/hooks/useSession"
import {
  BringYourOwnPropsHeader,
  useNotificationCount,
} from "@planningcenter/cc-nav"
import WindowDimensionsProvider from "source/shared/contexts/WindowDimensionsProvider"
import { JoltContext, JoltProvider } from "source/shared/contexts/JoltContext"
import { QRCode, QRCodeLink, QRCodeProvider } from "source/shared/QRCode"
import { WebBootContext } from "source/publishing/WebBoot"
import { DraftModeBanner } from "source/publishing/DraftMode"
import { ReactToPublishingJoltEvents } from "source/publishing/ReactToPublishingJoltEvents"
import { TitleTemplate } from "source/TitleTemplate"
import { extractMenuItems } from "source/shared/extractMenuItems"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import useQueryString from "source/shared/hooks/useQueryString"
import classNames from "classnames"
import { useEmbedded } from "source/calendar/hooks/useEmbedded"
import { HelmetProvider } from "react-helmet-async"
import OpenGraphTags from "./shared/OpenGraphTags"

Layout.propTypes = {
  children: node.isRequired,
}

export const LayoutContext = createContext()

export function isChurchCenterApp() {
  return location.search.includes("source=cca")
}

// Church Center App uses the window to communicate the color scheme to Church Center Web rendered inside
// of web views. The app *MUST* communicate the theme to the web view. We make no inferences.
// The agreed upon contract is `forceTheme`, and `setForceTheme`.
//
// window.forceTheme = ''
// window.setForceTheme('')
//
// Church Center Web, on the other hand, follows the system's prefered color scheme.
function useColorScheme() {
  const PREFERS_DARK = "(prefers-color-scheme: dark)"
  const features = useContext(WebBootContext).features
  const [queryParams] = useQueryString({
    theme: null,
  })
  const [storedTheme, setStoredTheme] = useLocalStorage(
    "ChurchCenterTheme",
    null,
  )
  let followSystemTheme
  let defaultColorScheme

  if (window.ReactNativeWebView) {
    followSystemTheme = false
    defaultColorScheme = window.forceTheme || "light"
  } else if (features.ROLLOUT_dark_mode && storedTheme) {
    followSystemTheme = false
    defaultColorScheme = storedTheme || "light"
  } else {
    followSystemTheme = false
    defaultColorScheme = "light"
  }

  const [chosenColorScheme, setChosenColorScheme] = useState(defaultColorScheme)

  // Put queryParam-defined theme in local storage
  useEffect(() => {
    if (queryParams.theme !== null) setStoredTheme(queryParams.theme)
  }, [queryParams.theme])

  // Make setForceTheme available to React Native
  useEffect(() => {
    if (window.ReactNativeWebView) window.setForceTheme = setChosenColorScheme
  }, [!!window.ReactNativeWebView, setChosenColorScheme])

  // Actually follow the system theme changing.
  // Not attached while we roll-out dark mode behind a query param.
  useEffect(() => {
    if (!followSystemTheme) return
    const media = window.matchMedia(PREFERS_DARK)
    const handleChange = (event) =>
      setChosenColorScheme(event.matches ? "dark" : "light")
    media.addEventListener("change", handleChange)
    return () => media.removeEventListener("change", handleChange)
  }, [followSystemTheme, setChosenColorScheme])

  // Apply the chosen scheme to the document body.
  useEffect(() => {
    let colorScheme

    switch (chosenColorScheme) {
      case "dark-low":
      case "dark":
        colorScheme = "dark"
        break
      default:
        colorScheme = "light"
    }

    document.body.setAttribute("data-color-scheme", colorScheme)
    document.body.setAttribute("data-contrast", "low")
    document.body.setAttribute("data-background-color", "")
  }, [chosenColorScheme])

  // Update the theme based on local storage theme
  useEffect(() => {
    setChosenColorScheme(storedTheme)
  }, [storedTheme])
}

export function Layout({ children }) {
  const { flash } = useContext(ServerRenderedProps)
  const { error } = flash
  const [state, dispatch] = useLayoutReducer()
  const { embedded } = useEmbedded()
  useColorScheme()

  return (
    <LayoutContext.Provider value={[state, dispatch]}>
      <JoltProvider>
        <ReactToPublishingJoltEvents />
        <QRCodeProvider>
          <WindowDimensionsProvider>
            <BreadcrumbNavigationProvider>
              <HelmetProvider>
                <OpenGraphTags />
                <TitleTemplate />
                <PageContainer layoutWidth={state.layoutWidth}>
                  <DraftModeBanner />
                  {!embedded && !isChurchCenterApp() && <Header />}
                  {!isChurchCenterApp() && <BreadcrumbNavigationPortal />}

                  <div
                    id="main_page_content"
                    role="main"
                    className={classNames("f-1", {
                      "page-content": !embedded,
                      "pb-0": !state.footerVisible,
                    })}
                  >
                    <div
                      className={`f-1 container container--${state.layoutWidth}`}
                    >
                      {error && (
                        <AlertMessage className="mb-3">{error}</AlertMessage>
                      )}
                      {embedded ? (
                        <Suspense fallback={<Loading />}>{children}</Suspense>
                      ) : (
                        <ProtectAgainstChangingSessions>
                          <Suspense fallback={<Loading />}>{children}</Suspense>
                        </ProtectAgainstChangingSessions>
                      )}
                    </div>
                  </div>
                  {!isChurchCenterApp() && <QRCode />}
                  {!embedded && !isChurchCenterApp() && <Footer />}
                </PageContainer>
              </HelmetProvider>
            </BreadcrumbNavigationProvider>
          </WindowDimensionsProvider>
        </QRCodeProvider>
      </JoltProvider>
    </LayoutContext.Provider>
  )
}

export function mapReachMenuLinkProps({ href, ...rest }) {
  if (href.startsWith(window.origin)) href = href.replace(window.origin, "")
  const isChurchCenterHref = href.startsWith("/")
  const key = href
  if (isChurchCenterHref) return { as: Link, key, to: href, ...rest }
  else
    return {
      as: "a",
      key,
      href,
      target: "_blank",
      rel: "noopener noreferrer",
      ...rest,
    }
}

function PageContainer({ layoutWidth = "", children }) {
  return (
    <div className={`page-container d-f fd-c ${layoutWidth}`}>{children}</div>
  )
}
PageContainer.propTypes = {
  layoutWidth: string,
  children: node,
}

function Header() {
  const [visible, setVisible] = useLayoutHeaderVisible()
  useEffect(() => {
    if (visible === "initialRender") {
      const timeout = window.setTimeout(() => setVisible(true), 100)
      return () => window.clearTimeout(timeout)
    }
  }, [visible])

  const opacity = visible === true ? "1.0" : "0.0"
  return (
    <div
      style={{
        opacity,
        transition: "opacity 0.4s ease",
      }}
    >
      <BringYourOwnPropsHeader
        {...useHeaderPropsBuiltFromPublishingWebBoot()}
      />
    </div>
  )
}

function Footer() {
  const [visible] = useLayoutFooterVisible()
  const { layout } = useContext(ServerRenderedProps)
  const {
    organization_contact_email: email,
    organization_contact_phone: phone,
    organization_name: name,
  } = layout

  if (!visible) return null

  return (
    <footer className="site-footer" role="contentinfo">
      <ul className="site-footer__links o-2 o-1@sm">
        <li className="site-footer__link">
          <a
            className="c-brand"
            href="https://planning.center/terms/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
        </li>
        <li className="site-footer__link">
          <a
            className="c-brand"
            href="https://planning.center/privacy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </li>
      </ul>

      <ul className="site-footer__links o-1 o-2@sm">
        <li className="site-footer__link">{name}</li>
        {email && (
          <li className="site-footer__link">
            <a className="fw-400 c-tint2" href={`mailto:${email}`}>
              {email}
            </a>
          </li>
        )}

        {phone && (
          <li className="site-footer__link">
            <a className="fw-400 c-tint2" href={`tel:${phone}`}>
              {phone}
            </a>
          </li>
        )}
      </ul>
      <QRCodeLink />
    </footer>
  )
}

function useHeaderPropsBuiltFromPublishingWebBoot() {
  const {
    currentOrganization,
    currentPerson,
    draftMenu,
    draftMode,
    publishedMenu,
  } = useContext(WebBootContext)
  const { notificationsChannel } = useContext(JoltContext)

  const menu = draftMode
    ? extractMenuItems(draftMenu)
    : extractMenuItems(publishedMenu)
  const organizationDisplayName = currentOrganization.attributes.name
  const organizationImageUrl = currentOrganization.attributes.avatar_url
  const personDisplayName = currentPerson.attributes.first_name
  const personImageUrl = currentPerson.attributes.avatar_url + "?g=120x120%23"
  const personIdentified = currentPerson.id !== "anonymous"

  // NOTE: This isn't truly reactive... but it seems to work 'good enough' for today.
  const notificationDelay = window.location.pathname.startsWith(
    "/notifications",
  )
    ? 0
    : 1000

  const notificationCount = useNotificationCount({
    personIdentified,
    notificationsChannel,
    notificationDelay,
  })

  const automaticallyAppendedLinks = ["/profile", "/logout", "/login"]
  const links = menu
    .filter(({ href }) => !automaticallyAppendedLinks.includes(href))
    .map(mapReachMenuLinkProps)

  const loginLink = mapReachMenuLinkProps({
    href: `/login?return=${window.location.href}`,
    children: "Log in",
  })

  const organizationLink = mapReachMenuLinkProps({
    href: "/home",
    children: "Home",
  })

  const notificationLink = mapReachMenuLinkProps({
    href: "/notifications",
  })

  const myChurchCenterLink = mapReachMenuLinkProps({ href: "/me" })

  return {
    links,
    loginLink,
    myChurchCenterLink,
    notificationCount,
    notificationLink,
    organizationDisplayName,
    organizationImageUrl,
    organizationLink,
    personDisplayName,
    personIdentified,
    personImageUrl,
  }
}

export function useLayoutReducer(
  initialLayoutState = {
    layoutWidth: "narrow",
    footerVisible: true,
    headerVisible: "initialRender",
    embedded: false,
  },
) {
  function reducer(state = initialLayoutState, action) {
    switch (action.type) {
      case "setLayoutWidth":
        return { ...state, layoutWidth: action.payload }
      case "showFooter":
        return { ...state, footerVisible: true }
      case "hideFooter":
        return { ...state, footerVisible: false }
      case "showHeader":
        return { ...state, headerVisible: true }
      case "hideHeader":
        return { ...state, headerVisible: false }
      default:
        return state
    }
  }

  return useReducer(reducer, initialLayoutState)
}

export const useLayoutWidth = () => {
  const [state, dispatch] = useContext(LayoutContext)

  return [
    state.layoutWidth,
    (layoutWidth) => {
      dispatch({
        type: "setLayoutWidth",
        payload: layoutWidth,
      })
    },
  ]
}

export const useLayoutHeaderVisible = () => {
  const [state, dispatch] = useContext(LayoutContext)
  return [
    state.headerVisible,
    (visible) => {
      dispatch({ type: visible ? "showHeader" : "hideHeader" })
    },
  ]
}

export const useLayoutFooterVisible = () => {
  const [state, dispatch] = useContext(LayoutContext)
  return [
    state.footerVisible,
    (visible) => {
      dispatch({ type: visible ? "showFooter" : "hideFooter" })
    },
  ]
}

export const useHiddenHeader = () => {
  const [_visible, setVisible] = useLayoutHeaderVisible()
  useEffect(() => {
    setVisible(false)
    return () => setVisible(true)
  }, [])
}

export const HideFooter = () => {
  const [_visible, setVisible] = useLayoutFooterVisible()
  useEffect(() => {
    setVisible(false)
    return () => setVisible(true)
  }, [])

  return null
}

export const FullscreenLayout = ({ children }) => {
  const [currentLayoutWidth, setLayoutWidth] = useLayoutWidth()

  useEffect(() => {
    setLayoutWidth("fullscreen")
    return () => {
      setLayoutWidth(currentLayoutWidth)
    }
  }, [])

  return <>{children}</>
}

FullscreenLayout.propTypes = {
  children: node.isRequired,
}

export const WideLayout = ({ children }) => {
  const [currentLayoutWidth, setLayoutWidth] = useLayoutWidth()

  useEffect(() => {
    setLayoutWidth("wide")
    return () => {
      setLayoutWidth(currentLayoutWidth)
    }
  }, [])

  return <>{children}</>
}

WideLayout.propTypes = {
  children: node.isRequired,
}

export const SermonEpisodeLayout = ({ children }) => {
  const [currentLayoutWidth, setLayoutWidth] = useLayoutWidth()

  useEffect(() => {
    setLayoutWidth("sermon-episode-layout")
    return () => {
      setLayoutWidth(currentLayoutWidth)
    }
  }, [])

  return <>{children}</>
}

SermonEpisodeLayout.propTypes = {
  children: node.isRequired,
}

export const NarrowLayout = ({ children }) => {
  const [currentLayoutWidth, setLayoutWidth] = useLayoutWidth()

  useEffect(() => {
    setLayoutWidth("narrow")
    return () => {
      setLayoutWidth(currentLayoutWidth)
    }
  }, [])

  return <>{children}</>
}

NarrowLayout.propTypes = {
  children: node.isRequired,
}
